import React from "react";

import styles from "./summary.module.css";
import usePhase from "utils/usePhase";
import useEditing, { useEditableInput } from "./useEditing";
import useStore from "utils/useStore";
import validate from "utils/validate";

import { useReligions } from "views/_contact";

const Contact = () => {
  const t = usePhase("summary");
  const deceasedName = useStore((state) => state.deceasedName);
  const deceasedSocialId = useStore((state) => state.deceasedSocialId);
  const deceasedChurch = useStore((state) => state.deceasedChurch);
  const deceasedWishes = useStore((state) => state.deceasedWishes);
  const ordererName = useStore((state) => state.ordererName);
  const ordererPhone = useStore((state) => state.ordererPhone);
  const ordererEmail = useStore((state) => state.ordererEmail);
  const ordererRelationship = useStore((state) => state.ordererRelationship);

  const religions = useReligions();

  const [isEditing, Edit, ConfirmCancel] = useEditing(true);
  const [Input, Dropdown, save] = useEditableInput();

  return (
    <div className={styles.block}>
      <div className={styles.subtitle}>
        <h3 className="h3">{t("contact")}</h3>
        <Edit />
      </div>
      {!isEditing && (
        <div className={styles.content}>
          <div className={styles.subblocktitle}>{t("deceased")}:</div>
          <div className={styles.row}>
            <div className={styles.key}>{t("name")}</div>
            <div className={styles.value}>{deceasedName}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("social_id")}</div>
            <div className={styles.value}>{deceasedSocialId}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("church")}</div>
            <div className={styles.value}>{deceasedChurch?.title}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("deceased_wishes")}</div>
            <div className={styles.value}>{deceasedWishes}</div>
          </div>

          <div className={styles.subblocktitle}>{t("orderer")}:</div>
          <div className={styles.row}>
            <div className={styles.key}>{t("name")} *</div>
            <div className={styles.value}>{ordererName}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("email")} *</div>
            <div className={styles.value}>{ordererEmail}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("phone")}</div>
            <div className={styles.value}>{ordererPhone}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("relationship")}</div>
            <div className={styles.value}>{ordererRelationship}</div>
          </div>
        </div>
      )}
      {isEditing && (
        <div className={styles.content}>
          <div className={styles.subblocktitle}>{t("deceased")}:</div>
          <div className={styles.row}>
            <div className={styles.key}>{t("name")}</div>
            <Input valueKey="deceasedName" value={deceasedName} />
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("social_id")}</div>
            <Input valueKey="deceasedSocialId" value={deceasedSocialId} />
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("church")}</div>
            <Dropdown
              valueKey="deceasedChurch"
              options={religions}
              placeholder={t("select_religion")}
              value={deceasedChurch}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("deceased_wishes")}</div>
            <Input valueKey="deceasedWishes" value={deceasedWishes} />
          </div>

          <div className={styles.subblocktitle}>{t("orderer")}:</div>
          <div className={styles.row}>
            <div className={styles.key}>{t("name")} *</div>
            <Input
              valueKey="ordererName"
              value={ordererName}
              placeholder={t("remember_orderer_name")}
              validate={(value) => value !== ""}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("email")} *</div>
            <Input
              valueKey="ordererEmail"
              value={ordererEmail}
              placeholder={t("remember_orderer_email")}
              validate={validate.email}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("phone")}</div>
            <Input valueKey="ordererPhone" value={ordererPhone} />
          </div>
          <div className={styles.row}>
            <div className={styles.key}>{t("relationship")}</div>
            <Input valueKey="ordererRelationship" value={ordererRelationship} />
          </div>
        </div>
      )}
      <ConfirmCancel
        confirm={() => {
          save();
        }}
      />
      {/* Show disabled it there is errors, done via CSS */}
      <ConfirmCancel disabled />
    </div>
  );
};

export default Contact;
